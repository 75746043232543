import request from "@/api/service";
import qs from 'qs';

const api = {
   page: '/goods/seckillProduct/getPreferencePage',
   preferenceProduct: '/goods/seckillProduct/getPreferenceProduct',
   create: '/goods/seckillProduct/savePreference',
   notActiveProduct: '/goods/productInfo/getNotActiveProduct',
   update: '/goods/seckillProduct/updatePreference',
   delete: '/goods/seckillProduct/delete',
   updateStatus: '/goods/seckillProduct/updatePreference'
}

export function getPage(params) {
   return request({
      url: api.page,
      method: 'POST',
      data: qs.stringify(params)
   })
}

export function getGoodsPage(params) {
   return request({
      url: api.preferenceProduct,
      method: 'POST',
      data: qs.stringify(params)
   })
}

export function getNotActiveProduct(params) {
   return request({
      url: api.notActiveProduct,
      method: 'POST',
      data: qs.stringify(params)
   })
}

export function savePreference(params, action) {
   return request({
      url: api[action],
      method: 'POST',
      data: params
   })
}

export function delPreference(params) {
   return request({
      url: api.delete,
      method: 'POST',
      data: qs.stringify(params)
   })
}

export function closePreference(params) {
   return request({
      url: api.updateStatus,
      method: 'POST',
      data: params
   })
}
