<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <label>任务名称：</label>
            <el-input v-model.trim="params.mainTitle" placeholder="任务名称" clearable></el-input>
          </el-row>
          <el-button @click="handleQuery(true)" class="bg-gradient" type="primary" icon="el-icon-search">搜索</el-button>
          <el-button @click="handleQuery(false)" icon="el-icon-refresh-right" plain>重置</el-button>
        </el-row>
        <el-row class="table-box">
          <el-row type="flex" justify="space-between">
            <el-tabs v-model="params.status" @tab-click="handleTab">
              <el-tab-pane label="全部" name=""></el-tab-pane>
              <el-tab-pane label="活动中" name="ACTIVE"></el-tab-pane>
              <el-tab-pane label="已结束" name="OVER"></el-tab-pane>
            </el-tabs>
            <el-row class="right-btn">
              <el-button class="bg-gradient" @click="handleCreate('create')">创建特惠商品</el-button>
            </el-row>
          </el-row>
          <el-table v-loading="loading" :data="tableData" :stripe="true" border fit>
            <el-table-column label="序号"  width="80">
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column prop="mainTitle" label="任务名称" width="300"></el-table-column>
            <el-table-column prop="productNum" label="商品数量"></el-table-column>
            <el-table-column label="查看商品">
              <template slot-scope="scope"><el-button @click="lookGoodsList(scope.row.id)">查看商品</el-button></template>
            </el-table-column>
            <el-table-column prop="salesVolume" label="商品总销量"></el-table-column>
            <el-table-column label="特惠时段" width="250">
              <template slot-scope="scope">{{ scope.row.startDate }}至{{ scope.row.endDate }}</template>
            </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">{{ scope.row.status | filterStatus(that) }}</template>
            </el-table-column>
            <el-table-column label="操作" width="230">
              <template slot-scope="scope">
                <el-button @click="handleCreate('look')" type="text">查看</el-button>
                <el-button @click="handleCreate('update', scope.row)" type="text">编辑</el-button>
                <el-button v-if="scope.row.status === 'ACTIVE'" @click="handleClose(scope.row.id)" type="text">
                  <el-link type="danger">关闭活动</el-link>
                </el-button>
                <el-button @click="handleDel(scope.row.id)" type="text">
                  <el-link type="danger">删除</el-link>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <pagination :total="total" :page-size="params.limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="handleCurrPage"/>
        </el-row>
      </el-row>
    </div>
    <el-dialog title="查看商品" :visible.sync="visible" width="550px">
      <el-table :data="goodsData" :stripe="true" border fit>
        <el-table-column prop="id" label="商品ID"></el-table-column>
        <el-table-column prop="name" label="商品名称"></el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" :total="goodsTotal"></el-pagination>
      <span slot="footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button @click="visible = false" type="primary">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getPage, getGoodsPage, delPreference,
  closePreference } from '@/api/shop/promotion/preferential';
import { getDict } from "@/common/js/common";
import { mapState } from 'vuex';
export default {
  name: 'preferentialList',
  data() {
    return {
      crumbs: new Map([
          ['特惠商城'], ['促销中心'], ['限时特惠']
      ]),
      that: this,
      params: {
        page: 1,
        limit: 0,
        type: 'PREFERENCE',
        status: '',
        mainTitle: ''
      },
      loading: true,
      tableData: [],
      total: 0,
      visible: false,
      goodsData: [],
      goodsPage: 1,
      goodsTotal: 0
    }
  },
  computed: {
    ...mapState(['hotelInfo', 'dictData'])
  },
  mounted() {
    getDict('preferential-goods-status')
    this.params.limit = sessionStorage.getItem('pageSize') * 1;
    this.getPreferenceList()
  },
  methods: {
    // 获取特惠活动列表
    getPreferenceList() {
      const params = { ...this.params, hotelId: this.hotelInfo.id };
      params.status === '0' && (params.status = '')
      // if (this.hotelInfo.hotelName === '达易住特惠') delete params.hotelId
      getPage(params).then(({ success, total, records }) => {
        if (!success) return;
        this.loading = false
        this.total = total
        this.tableData = records
      })
    },
    // 查询特惠活动
    handleQuery(bool) {
      this.params.page = 1
      if (bool) return this.getPreferenceList()
      this.params.mainTitle = ''
      this.params.productId = ''
    },
    // 改变每页数
    changePageNum(num) {
      this.params.limit = num
      this.getPreferenceList()
    },
    // 改变当前页
    handleCurrPage(num) {
      this.params.page = num
      this.getPreferenceList()
    },
    // 改变当前tab
    handleTab() {
      this.params.page = 1
      this.getPreferenceList()
    },
    // 查看商品列表
    lookGoodsList(id) {
      const params = { limit: 7, page: this.goodsPage, id }
      getGoodsPage(params).then(({ success, records, total }) => {
        if (!success) return;
        this.visible = true
        this.goodsTotal = total
        this.goodsData = records
      })
    },
    // 创建特惠商品
    handleCreate(action, row) {
      if (action === 'update') sessionStorage.setItem('preferenceInfo', JSON.stringify(row))
      this.$router.push({ path: '/preferential_mall/time_limit_preferential/create', query: { action } })
    },
    // 删除特惠活动
    handleDel(ids) {
      this.$confirm('如果删除，需要重新新建特惠商品，将在售商品放入限时特惠列表内', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        delPreference({ ids }).then(({ success }) => {
          if (!success) return;
          this.getPreferenceList()
          this.$message({ message: '删除成功！', type: 'success' })
        })
      }).catch(_ => {})
    },
    // 关闭特惠活动
    handleClose(id) {
      this.$confirm('如果关闭活动，则会从限时特惠处删除，状态改为已结束，需要重新编辑后上架。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        closePreference({ id, status: 'OVER' }).then(({ success }) => {
          if (!success) return;
          this.getPreferenceList()
          this.$message({ message: '关闭成功！', type: 'success' })
        })
      }).catch(_ => {})
    }
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      oldVal && newVal.id !== oldVal.id && this.getPreferenceList()
    }
  },
  filters: {
    filterStatus(val, that) {
      if (val && that.dictData['preferential-goods-status']) return that.dictData['preferential-goods-status'][val] || val
    }
  }
}
</script>
